<template>
	<v-container
		fill-height
		class="bg-error"
	>
		<v-row
			align="center"
		>
			<v-col
				align="center"
			>
				<div class="text-center">
					<v-icon 
						large 
						color="red"
					>mdi-alert-outline</v-icon>
				</div>
				<div class="text-white text-center text-subtitle-1 mt-10">
					<p>인터넷 연결상태가 좋지 않습니다.</p>
					<p>확인 후 다시 이용해주세요.</p>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default{
		name: 'ErrorNetwork'
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,data: function(){
			return {
				program: {
					title: '네트워크 오류'
					,not_navigation: true
					,not_title: true
				}
			}
		}	
	}
</script>

<style>
	.bg-error {background-color: #15324E !important;}
	.fill-height {height: 100%;}
</style>